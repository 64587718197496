import * as $ from 'jquery';
import 'datatables';

export default (function () {

    $(document).ready(function () {
        // Setup - add a text input to each footer cell
        $('#dataTable thead tr')
            .clone(true)
            .addClass('filters')
            .appendTo('#dataTable thead');

        var table = $('#dataTable').DataTable({
            orderCellsTop: true,
            stateSave: true,
            responsive: true,
            orderMulti: true,
            fixedHeader: true,
            "language": {
                search: '<i class="fa fa-filter" aria-hidden="true"></i>',
                searchPlaceholder: 'Rechercher',
                "lengthMenu": "Afficher _MENU_ entrées par page",
                "info": "Affichage page _PAGE_ / _PAGES_",
                "zeroRecords": "Pas d'entrées disponibles",
                "infoFiltered": "(filtre sur _MAX_ total entrées)",
                "infoEmpty": "Aucune donnée disponible",
                paginate: {
                    previous: 'Précédent',
                    next:     'Suivant'
                }
            },
                "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
            order: [],
            columnDefs: [ {
                'targets': [], /* column index [0,1,2,3]*/
                'orderable': false, /* true or false */
            }],
            initComplete: function () {
                var api = this.api();

                // For each column
                api
                    .columns()
                    .eq(0)
                    .each(function (colIdx) {
                        // Set the header cell to contain the input element
                        var cell = $('.filters th').eq(
                            $(api.column(colIdx).header()).index()
                        );
                        var title = $(cell).text();

                        $(cell).html('<input type="text" name="data-filter" style="width: 100%;" placeholder="' + title + '" />');

                        // On every keypress in this input
                        $(
                            'input',
                            $('.filters th').eq($(api.column(colIdx).header()).index())
                        )
                            .off('keyup change')
                            .on('change', function (e) {
                                // Get the search value
                                $(this).attr('title', $(this).val());
                                var regexr = '({search})'; //$(this).parents('th').find('select').val();

                                var cursorPosition = this.selectionStart;
                                // Search the column for that value
                                api
                                    .column(colIdx)
                                    .search(
                                        this.value != ''
                                            ? regexr.replace('{search}', '(((' + this.value + ')))')
                                            : '',
                                        this.value != '',
                                        this.value == ''
                                    )
                                    .draw();
                            })
                            .on('keyup', function (e) {
                                e.stopPropagation();

                                $(this).trigger('change');
                            });
                    });
            },
        });
        $("#dataTable_filter").append("<button type='button' class='btn btn-success btn-color' style='float: right;margin-left: 35px;' id='reset_data'>Reset filtre</button>");
        $("#reset_data").on("click", function () {
            var table = $('#dataTable').DataTable({responsive: true,orderMulti: true, stateSave: true});
            table.search('').draw();
            $(this).val('');

        });
    });

}());
