import * as $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

export default (function () {

  $("#date_start").on('change', function() {

    var date = $('#date_start').datepicker({dateFormat: 'mm-dd-yy'}).val();

  })

  $("#date_start_date").on('change', function() {

    var date = $('#date_start_date').datepicker({dateFormat: 'mm-dd-yy'}).val();

  })


}());