import * as $ from 'jquery';

export default (function () {
  // ------------------------------------------------------
  // @Window Resize
  // ------------------------------------------------------

  /**
   * NOTE: Register resize event for Masonry layout
   */
  const EVENT = document.createEvent('UIEvents');
  window.EVENT = EVENT;
  EVENT.initUIEvent('resize', true, false, window, 0);


  window.addEventListener('load', () => {
    /**
     * Trigger window resize event after page load
     * for recalculation of masonry layout.
     */
    window.dispatchEvent(EVENT);
  });

  // ------------------------------------------------------
  // @External Links
  // ------------------------------------------------------

  // Open external links in new window
  $('a')
    .filter('[href^="http"], [href^="//"]')
    .not(`[href*="${window.location.host}"]`)
    .attr('rel', 'noopener noreferrer')
    .attr('target', '_blank');

  // ------------------------------------------------------
  // @Resize Trigger
  // ------------------------------------------------------

  // Trigger resize on any element click
  document.addEventListener('click', () => {
    window.dispatchEvent(window.EVENT);
  });

  $("#date_start").on("change", function(){


    var dateAr = $("#date_start").val().split('/');
    var newDate = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
    $('#input').val(newDate);

  })


  $("#date_end").on("change", function(){
    var start_date = $("#date_start").val();
    var dateAr = $("#date_end").val().split('/');
    var newDate = dateAr[2] + '-' + dateAr[1] + '-' + dateAr[0];
    $('#input2').val(newDate);
  })

  $('#buttonID').click(function(){
    var emailT = $.trim($("input[name='email']").val());
    var pwdT = $.trim($("input[name='pwd']").val());
    if ((emailT === 'eric.froidefond@maaf.fr') && (pwdT === 'XsM9F8Erki42'))
    {
      window.location = 'https://preprod-sup-websiv.dataneo.fr/dashboard.php'
    }else
    if ((emailT === 'richard.boinot@maaf.fr') && (pwdT === 'XsM9F8Erki42'))
    {
      window.location = 'https://preprod-sup-websiv.dataneo.fr/dashboard.php'
    }
    else if ((emailT === 'yann.coirier@maaf.fr') && (pwdT === 'XsM9F8Erki42'))
    {
      window.location = 'https://preprod-sup-websiv.dataneo.fr/dashboard.php'
    }
else if ((emailT === 'stephane.tastard@maaf.fr') && (pwdT === 'XsM9F8Erki42'))
    {
      window.location = 'https://preprod-sup-websiv.dataneo.fr/dashboard.php'
    }


  });
  $('#logout').click(function(){
    window.location = 'https://preprod-sup-websiv.dataneo.fr/'});

  }());
